import Vue from "vue";
import Router from "vue-router";
import store from "./store/main.js";
const Products = () =>
  import(/* webpackChunkName: "Products" */ "./views/Products.vue");
const Product = () =>
  import(/* webpackChunkName: "ProductsItem" */ "./views/ProductView.vue");
Vue.use(Router);

export default new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (to.name === "products" && from.name === "product") {
      return savedPosition;
    }
    if (to.name === "product" && from.name === "products") {
      return savedPosition;
    }
    if (to.name === "product" && from.name === "product") {
      return savedPosition;
    }
    if (to.name === "people" && from.name === "person") {
      return savedPosition;
    }
    if (to.name === "person" && from.name === "people") {
      return savedPosition;
    }
    if (to.name === "person" && from.name === "person") {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "Home" */ "./views/Home.vue"),
      beforeEnter: (to, from, next) => {
        next();
        if (store.state.home.length === 0) {
          store.dispatch("loadHome");
          store.dispatch("loadPeople");
          store.dispatch("loadAbout");
        }
      },
    },
    {
      path: "/about",
      name: "about",
      component: () =>
        import(/* webpackChunkName: "About" */ "./views/About.vue"),
      beforeEnter: (to, from, next) => {
        next();
        if (store.state.about.length === 0) {
          store.dispatch("loadAbout");
        }
      },
    },
    {
      path: "/channels",
      name: "channels",
      component: () =>
        import(/* webpackChunkName: "Channels" */ "./views/Channels.vue"),
      beforeEnter: (to, from, next) => {
        next();
        if (store.state.about.length === 0) {
          store.dispatch("loadAbout");
        }
      },
    },
    {
      path: "/policies",
      name: "policies",
      component: () =>
        import(/* webpackChunkName: "Policies" */ "./views/Policies.vue"),
      beforeEnter: (to, from, next) => {
        next();
        if (store.state.policies.length === 0) {
          store.dispatch("loadPolicies");
        }
      },
    },
    {
      path: "/terms",
      name: "terms",
      component: () =>
        import(/* webpackChunkName: "Terms" */ "./views/Terms.vue"),
      beforeEnter: (to, from, next) => {
        next();
        if (store.state.terms.length === 0) {
          store.dispatch("loadTerms");
        }
      },
    },
    {
      path: "/people",
      name: "people",
      component: () =>
        import(/* webpackChunkName: "People" */ "./views/People.vue"),
      children: [
        {
          path: "/people/:id",
          name: "person",
          component: () =>
            import(
              /* webpackChunkName: "PeopleItem" */ "./views/PeopleView.vue"
            ),
        },
      ],
      beforeEnter: (to, from, next) => {
        next();
        if (store.state.people.length === 0) {
          store.dispatch("loadPeople");
        }
      },
    },
    {
      path: "/products",
      name: "products",
      component: Products,
      beforeEnter: (to, from, next) => {
        next();
        if (store.state.products.length === 0) {
          store.dispatch("loadProducts", 0);
        }
      },
    },
    {
      path: "/products/:id",
      name: "product",
      components: {
        default: Products,
        product: Product,
      },
      beforeEnter: (to, from, next) => {
        next();
        if (store.state.products.length === 0) {
          store.dispatch("loadProducts", 0);
        }
      },
    },
    {
      path: "/cancel",
      name: "cancel",
      component: () =>
        import(/* webpackChunkName: "Cancel" */ "./views/Cancel.vue"),
    },
    {
      path: "/success",
      name: "success",
      component: () =>
        import(/* webpackChunkName: "Success" */ "./views/Success.vue"),
    },
    {
      path: "/manage",
      name: "manage",
      component: () =>
        import(/* webpackChunkName: "Manage" */ "./views/Manage.vue"),
    },
    {
      path: "/account",
      name: "manage",
      redirect: "/manage",
    },
    // {
    //   path: "/subscribe",
    //   name: "subscribe",
    //   component: () =>
    //     import(/* webpackChunkName: "Subscribe" */ "./views/Subscribe.vue"),
    // },
    {
      path: "*",
      redirect: "/",
    },
  ],
});
