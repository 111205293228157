import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/main.js";
import VueMeta from "vue-meta";
import VueLazyload from "vue-lazyload";
import VueScrollTo from "vue-scrollto";
import infiniteScroll from "vue-infinite-scroll";
import VueCookie from "vue-cookie";

Vue.use(VueCookie);
Vue.use(infiniteScroll);
Vue.use(VueScrollTo, {
  duration: 1000,
  offset: -39,
  delay: 400,
});
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VueLazyload);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
