export const shopModule = {
  namespaced: true,
  state: {
    view: "cart",
    cart: [],
    subscriptions: [
      {
        region: "switzerland",
        price: "price_1HS4GuCXnexdLGkjDUn1n1Nd",
      //   price: "price_1HPROFCXnexdLGkjPn2dPALy", // test
      },
      {
        region: "europe",
        price: "price_1HS4GuCXnexdLGkjfqjQmmWi",
        // price: "price_1HPROpCXnexdLGkjYPFcKqfT", // test
      },
      {
        region: "worldwide",
        price: "price_1HS4GuCXnexdLGkjwaWxNIIA",
        // price: "price_1HPRPCCXnexdLGkj3CjzSSKi", // test
      },
    ],
    customerId: null,
    cartTotal: 0,
    shippingCost: 0,
    orderID: null,
  },
  getters: {
    totalAmount: (state) => {
      let amount = 0;
      state.cart.forEach((item) => (amount += item.price * item.quantity));
      amount += state.shippingCost;
      return amount;
    },
  },
  mutations: {
    addToCart({ cart }, product) {
      const itemIndex = cart.findIndex((item) => item.id === product.id);
      if (itemIndex < 0) {
        cart.push(product);
      } else {
        cart[itemIndex].quantity++;
      }
    },
    removeFromCart({ cart }, productid) {
      const itemIndex = cart.findIndex((item) => item.id === productid);
      cart.splice(itemIndex, 1);
    },
    decreaseItem({ cart }, productid) {
      const itemIndex = cart.findIndex((item) => item.id === productid);
      cart[itemIndex].quantity--;
    },
    increaseItem({ cart }, productid) {
      const itemIndex = cart.findIndex((item) => item.id === productid);
      cart[itemIndex].quantity++;
    },
    changeView(state, newView) {
      state.view = newView;
    },
  },
};
