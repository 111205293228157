<template>
  <div
    id="app"
    v-if="!loading"
    :class="{
      active: cartOpen,
      product: $route.name === 'product'
    }"
    :style="{ '--active': activeColor }"
  >
    <header>
      <navigation :cats="site.categories"></navigation>
      <div class="nav-buttons">
        <search />
        <div
          class="nav-elem nav-cart cartopen"
          :class="{ active: cartNumber > 0 }"
        >
          <a class="cartopen" @click.prevent="toggleCart()">
            <img
              class="cartopen"
              src="@/assets/icons/carticon.svg"
              alt="Open Cart"
            />
          </a>
        </div>
      </div>
    </header>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <transition name="slide">
      <router-view name="product" />
    </transition>
    <foot :site="site" v-if="$route.name != 'about'" />
    <cart v-click-outside="hideCart" />
    <curs v-if="!isMobile()" :color="activeColor" />
    <cookie-warning />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import isMobile from "@/mixins/isMobile";
import { mapState } from "vuex";
const Navigation = () =>
  import(/* webpackChunkName: "Navigation" */ "@/components/Navigation.vue");
const Search = () =>
  import(/* webpackChunkName: "Search" */ "@/components/Search.vue");
const Cart = () =>
  import(/* webpackChunkName: "Cart" */ "@/components/Cart.vue");
const Foot = () =>
  import(/* webpackChunkName: "Footer" */ "@/components/Footer.vue");
const Curs = () =>
  import(/* webpackChunkName: "Cursor" */ "@/components/Cursor.vue");
const CookieWarning = () =>
  import(
    /* webpackChunkName: "CookieWarning" */ "@/components/CookieWarning.vue"
  );

export default {
  name: "App",
  metaInfo() {
    return {
      titleTemplate: "Präsens Editionen" + " %s"
    };
  },
  mixins: [isMobile],
  directives: {
    ClickOutside
  },
  components: {
    Navigation,
    Search,
    Cart,
    Foot,
    Curs,
    CookieWarning
  },
  data() {
    return {
      cartOpen: false,
      activeColor: "rgb(60, 255, 127)",
      loading: true
    };
  },
  beforeCreate() {
    this.$store.dispatch("loadSite").then(() => {
      this.loading = false;
    });
  },
  mounted() {
    this.$root.$on("closeCart", this.closeCart);
    this.$root.$on("openCart", this.openCart);
    this.$root.$on("changeColor", this.changeColor);
  },
  computed: {
    ...mapState(["site"]),
    cartNumber() {
      return this.$store.state.shop.cart.length;
    }
  },
  methods: {
    changeColor(newColor) {
      this.activeColor = newColor;
    },
    openCart() {
      this.cartOpen = true;
    },
    closeCart() {
      this.cartOpen = false;
    },
    toggleCart() {
      if (this.cartOpen) {
        this.$root.$emit("closeCart");
      } else {
        this.cartOpen = true;
        // this.$store.state.shop.view = "cart";
      }
    },
    hideCart(event) {
      if (!this.isMobile()) {
        if (this.cartOpen && !event.target.classList.contains("cartopen")) {
          this.cartOpen = false;
        }
        if (event.target.classList.contains("cartopen")) {
          if (this.cartOpen) {
            this.cartOpen = true;
          } else {
            this.cartOpen = false;
          }
        }
      }
    }
  }
};
</script>

<style global lang="scss">
@import "@/assets/css/colors";
@import "@/assets/css/fonts";
@import "@/assets/css/transitions";
@import "node_modules/reset-css/sass/_reset";

* {
  box-sizing: border-box;
}

::selection {
  background: var(--active);
  opacity: 1;
}

.nav-elem {
  height: var(--lh);
}
.nav-elem,
a,
a:active,
a:hover,
a:visited {
  color: var(--color);
  cursor: none;
  text-decoration: none;
  transition: $opacity;
  display: inline-block;
  @media only screen and (max-width: 768px) {
    cursor: pointer;
  }
}

.person-description a {
  text-decoration: none;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 14px;
    --random: random(10) - random(5) + deg;
    transform: translateY(-50%) rotate(-0.6deg);
    z-index: 0;
    mix-blend-mode: multiply;
    background-image: linear-gradient(
      90deg,
      transparent 0%,
      transparent 50%,
      var(--active) 50%,
      var(--active) 100%
    );
    background-size: 200% 50%;
    transition: background-position 0.5s linear;
    background-position: -100% 100%;
  }
}

.marker {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 14px;
    --random: random(10) - random(5) + deg;
    transform: translateY(-50%) rotate(-0.6deg);
    // z-index: -1;
    z-index: 0;
    mix-blend-mode: multiply;
    // mix-blend-mode: multiply;
    background-image: linear-gradient(
      90deg,
      transparent 0%,
      transparent 50%,
      var(--active) 50%,
      var(--active) 100%
    );
    background-size: 200% 50%;
    transition: background-position 0.5s linear;
    background-position: 0 center;
  }
  &.bgw {
    &::after {
      background-image: linear-gradient(
        90deg,
        transparent 0%,
        transparent 50%,
        #fff 50%,
        #fff 100%
      );
      transition: background-position 0.5s linear;
      background-position: -100% 100%;
    }
    &:hover,
    &.active {
      &::after {
        background-image: linear-gradient(
          90deg,
          transparent 0%,
          transparent 50%,
          var(--active) 50%,
          var(--active) 100%
        );
      }
    }
  }
  &.active,
  &.perm,
  &:hover,
  &.router-link-active:not(.logo) {
    &::after {
      transition: background-position 0.5s linear;
      background-position: -100% 100%;
    }
  }
  &.special {
    &::after {
      z-index: 0;
      mix-blend-mode: multiply;
    }
  }
}

body,
html {
  font-family: "Whyte", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--fs);
  line-height: var(--lh);
  cursor: none;
  @media only screen and (max-width: 768px) {
    cursor: auto;
  }
}

.title,
h1 {
  font-family: "WhyteInktrap", Helvetica, sans-serif;
  font-size: calc(3 * var(--fs));
  line-height: calc(3 * var(--fs));
  margin-block-start: 0;
  margin-block-end: 0;
  @media only screen and (max-width: 768px) {
    font-size: calc(2 * var(--fs));
    line-height: calc(2 * var(--fs));
  }
  &.big {
    font-size: calc(5 * var(--fs));
    line-height: calc(5 * var(--fs));
    @media only screen and (max-width: 768px) {
      font-size: calc(2 * var(--fs));
      line-height: calc(2 * var(--fs));
    }
  }
}
.title em {
  font-family: "WhyteInktrapItalic";
}
em {
  font-family: "WhyteItalic";
}
p {
  a {
    text-decoration: underline;
  }
}
p:not(:last-of-type) {
  margin-bottom: var(--lh);
}
.tnum {
  font-feature-settings: "tnum";
}
.tac {
  text-align: center;
}
.tr {
  text-align: right;
}

#app {
  header {
    position: fixed;
    top: 0;
    left: 0;
    // right: 0;
    width: 100%;
    max-width: 100%;
    background-color: var(--white);
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: width 0.5s linear;
    will-change: width;
    .nav-buttons {
      display: flex;
      padding: calc(0.5 * var(--lh)) var(--lh) calc(0.5 * var(--lh))
        calc(0.5 * var(--lh));
      > div {
        display: inline-flex;
      }
      img {
        height: var(--lh);
        width: var(--lh);
        display: block;
      }
      .nav-cart {
        &.active:after {
          content: "";
          position: absolute;
          right: 22px;
          top: 12px;
          background-color: var(--orange);
          height: 10px;
          width: 12px;
          z-index: -1;
        }
      }
      .nav-search {
        margin-right: calc(0.5 * var(--lh));
      }
    }
  }
  main {
    width: 100%;
    margin-top: calc(2 * var(--lh));
    min-height: calc(100vh - (2 * var(--lh)));
    // padding-right: 5px;
    background: white;
    transition: width 0.5s linear, transform 0.5s linear, opacity 0.5s;
    // will-change: width, transform;
    &.loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1 {
        animation: blink 1.5s infinite alternate;
      }
    }
    .loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1 {
        animation: blink 1.5s infinite alternate;
      }
    }
    .sub-links {
      position: fixed;
      right: 0;
      bottom: 0;
      width: auto;
      justify-content: flex-end;
      display: flex;
      a {
        margin-right: calc(0.5 * var(--lh));
      }
    }
  }
  .pad {
    padding: var(--lh);
  }
  .pad-txt {
    padding: calc(0.5 * var(--lh));
  }
  .mar-b {
    margin-bottom: var(--lh);
  }
  #footer {
    transition: $slideout;
  }
  &.product {
    #footer {
      transform: translate3d(-50vw, 0, 0);
      @media only screen and (max-width: 1024px) {
        transform: translate3d(0, 0, 0);
      }
      #footer-right {
        padding-left: calc(0.5 * var(--lh));
      }
    }
    .colorpicker:hover {
      transform: translate3d(-5px, 0, 0);
    }
    .product-view {
      right: -5px !important;
    }
    header {
      width: calc(100% - 50vw);
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    main {
      width: calc(100% - 50vw);
      transform: translate3d(0, 0, 0);
      @media only screen and (max-width: 1024px) {
        width: 100%;
        visibility: hidden;
      }
    }
  }
  &.product:not(.active) {
    main.products {
      .product-list,
      .product-list-header {
        grid-template-columns: 1fr 80px 100px;
        .product-list-catalognumber {
          display: none;
        }
        .product-list-artist {
          display: none;
        }
        .product-list-category {
          display: none;
        }
      }
      .product-img-preview {
        &.Publication img {
          transform: translateX(-50%) !important;
        }
        &.Vinyl {
          img {
            transform: translateX(-50%) !important;
          }
          .vinyl {
            transform: translateX(-50%) !important;
          }
        }
        &.Cassette {
          img {
            transform: translateX(50%) !important;
          }
          .cassetteback {
            transform: translateX(-50%) !important;
          }
        }
      }
    }
  }
  &.active {
    #footer {
      transform: translate3d(-300px, 0, 0) !important;
      @media only screen and (max-width: 1024px) {
        transform: translate3d(calc(-100vw + 4px), 0, 0) !important;
      }
    }
    .product-view {
      transform: translate3d(100%, 0, 0);
      right: 0;
    }
    .colorpicker:hover {
      transform: translate3d(-5px, 0, 0);
    }
    main {
      width: calc(100% - 300px) !important;
      transform: translate3d(0, 0, 0);
      @media only screen and (max-width: 1024px) {
        width: 100% !important;
      }
    }
    header {
      width: calc(100% - 300px) !important;
      transform: translate3d(0, 0, 0);
      @media only screen and (max-width: 1024px) {
        width: 100% !important;
      }
    }
    .cart {
      transform: translate3d(0, 0, 0);
    }
  }

  .grid {
    display: grid;
    &.gap {
      grid-gap: var(--lh);
    }
    &.c-2 {
      grid-template-columns: repeat(2, 1fr);
      @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
</style>
